.header-wrapper {
    float: right;
}

.px-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.container-lg {
    padding: 0;
}

.header {

    &>.container-fluid:first-child {
        margin-left: auto;
        margin-right: 0;
        width: unset;
    }
}

.card-header {
    background-color: $azul-principal;
    color: white;
}

.container-lg {
    max-width: unset;
}

.side-menu-icon {
    margin-right: 10px;
}

button {
    color: white !important;
    background-color: $verde-permitido;
}

.file-input {
    display: none;
}

.file-input-fake-button {
    background-color: $verde-permitido;
    color: white;
    border-radius: 0.25rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    margin-top: 20px;
    cursor: pointer;
}

.divider {
    border: 1px solid $gray-300;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.hidden {
    display: none;
}

.spinner-results {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    background-color: white;
    padding: 30px;
    border-radius: 30px;
}

input {
    padding: 0 !important;
    border: none !important;
    border-bottom: 1px solid white !important;
    border-radius: 0 !important;
    background-color: $roxo !important;
    color: white !important;
    font-weight: 600;

    &::-webkit-input-placeholder {
        color: white !important;
    }

    &:focus,
    &:active {
        color: white !important;
        background-color: $roxo !important;
        border-color: white !important;
    }
}

.align-center {
    text-align: center;
}

label {
    padding: 0 !important;
    color: white !important;
    font-weight: 600;
}

.btn-primary {
    background-color: $verde-principal;
}

button {
    border-radius: 10px !important;
}

.header {
    background-image: url(../assets/img/header-bg.png);
}

.modal-dialog {
    max-width: none;
}

.body {
    padding-left: var(--cui-sidebar-occupy-start, 0) !important;
}

.sidebar-brand-full {
    height: 45px;
}

.wrapper {
    padding-left: 0 !important;
}

.sidebar.sidebar-fixed {
    margin: 15px;
    border-radius: 15px;
    left: 15px !important;
}

.sidebar-narrow-unfoldable {

    min-width: 75px;

    &:hover {

        .side-menu-text {
            display: initial;
        }
    }

    .side-menu-text {
        display: none;
    }
}

.sidebar {
    background-color: #E0E0E0;

    .nav-group.show,
    .nav-link.nav-group-toggle {
        background-color: #E0E0E0;
        color: #767676 !important;
    }

    .nav-group-items {

        .nav-item {
            padding-left: 0;
        }
    }

    .nav-group {
        padding-left: 16px;

        .nav-link {
            padding-left: 16px !important;

            &:hover {
                background-color: #767676;
                color: white !important;
                border-radius: 10px;
            }
        }
    }

    .nav-item {
        background-color: #E0E0E0;
        padding: 10px;
        padding-left: 16px;

        a {
            border-radius: 8px
        }

        .nav-link {
            color: #767676;

            &:hover {
                background-color: #767676;
                color: white;
            }
        }

        .nav-link.active {
            padding-left: 10px !important;
            margin-left: 5px;
            background-color: #4FE48B;
            color: #F9F9F9;
        }
    }
}

.bold {
    font-weight: bold;
}

.sidebar-toggler {
    bottom: 15px !important;
}

.leaflet-container {
    height: 100%;
    border-radius: 12px;
}