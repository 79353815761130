.filter-container {

    background-color: white;
    width: 100%;
    width: 100vw;
    position: fixed;
    margin-top: -24px;
    left: 0;
    z-index: 100;
    height: 166px;

    .sliders {
        font-size: 20px;
        color: $roxo;
    }

    .search-icon {
        line-height: 2.5;
        margin-right: 11px;
    }

    .filter-line {

        display: inline-flex;
        margin-bottom: 25px;
        padding-top: 25px;
        justify-content: center;
        width: 100%;

        .async-select {
            width: 400px;
            margin-right: 10px;

            input {
                background-color: rgba(0,0,0,0) !important;
                color: black !important;
            }
        }

        .capsule-filter {
            cursor: pointer;
            border: 1px solid #4FE48B;
            border-radius: 15px;
            padding-left: 10px;
            padding-right: 10px;
            margin-right: 10px;
            padding-top: 7px;

            .excluir-icon {
                margin-left: 5px;
            }

            span {
                color: #4FE48B;
            }

            i {
                color: #4FE48B;
                margin-right: 5px;
            }
        }
    }
}