.toolbar {

	.toolbar-container {
		display: inline-flex;
	}

	.toolbar-item {
		cursor: pointer;
		border: 1px solid #23282c;
		border-left: none;
		color: #23282c;
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 2;

		&:first-child {
			border-left: 1px solid #23282c;
		}

		&:hover {
			background-color: $gray-100;
		}
	}

	.active {
		background-color: white;

		i {
			font-weight: bold;
		}
	}
}
