.galery {

	.galery-container {
        display: inline-flex;
        flex-wrap: wrap;
        text-align: center;

        .galery-item {
            margin: 10px;
            border: 10px solid transparent;
            padding-right: 25px;

            input {
                margin-top: 20px;
            }

            &:hover {
                border: 10px solid $azul-principal;
                cursor: pointer;
            }
        }
    }

    img {
        max-width: 300px;
    }

    .delete-icon {
        color: #eb4034;
        font-size: 25px;
        position: absolute;
    }
}

.modal-photo {

    aspect-ratio: 1/1;
    max-width: fit-content;
    min-width: 700px;

    .modal-body {
		height: 80vh;
		overflow-y: scroll;
	}

    img {
        width: 100%;
        height: 100%;
    }
}