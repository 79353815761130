.signup {

	padding-top: 15%;
	height: 100vh;
	background-color: $roxo;

    .logo {
        width: 60%;
        margin: auto;
        margin-top: -15%;
        margin-bottom: 20px;
    }

    .white-potion {
        background-color: white;
        border-radius: 20px;
        z-index: 1;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .scrolable {
        overflow-y: scroll;
        height: 75vh;
    }

    .sign-footer {
        background-color: white;
        position: absolute;
        bottom: 0px;
        width: 100%;
        left: 0;
        z-index: 3;
        height: 60px;
        text-align: center;

        button {
            width: 40%;
            height: 40px;
            margin-top: 10px;
            font-size: 20px;

            &:first-child {
                margin-right: 10px;
            }

            &:last-child {
                margin-left: 10px;
            }
        }
    }

    h1 {
        color: $roxo;
        font-weight: 600;
        margin-top: 10px;
    }

    .forgot-pass {
        text-align: left;
        text-decoration: none;
        padding: 0;
        margin-top: 5px;
    }

    .row {
        padding: 15px;
        padding-left: 0;
        padding-right: 0;
    }

    .btn-link {
        color: $roxo;
    }

    .btn-primary {
        margin-top: 10px;
    }

	.link-login {
		color: $roxo;
		font-weight: bolder;
		text-decoration: underline;
	}

	.alert {
		top: 0;
		position: absolute;
		width: 100%;
		text-align: center;
		font-size: 18px;
		font-weight: 800;
	}

    .hints {
        border: 1px solid $verde-principal;
        color: $roxo;
        padding: 6px;
        margin-top: 15px;
        border-radius: 15px;
        margin-bottom: 45px;
    }

    input {
        padding: 0 !important;
        border: none !important;
        border-bottom: 1px solid $gray-700 !important;
        border-radius: 0 !important;
        background-color: white !important;
        color: $gray-700 !important;

        &::-webkit-input-placeholder {
            color: $gray-700 !important;
        }

        &:focus, &:active {
            color: $gray-700 !important;
            background-color: white !important;
            border-color: $gray-700 !important;
        }
    }

    .align-center {
        text-align: center;
    }

    label {
        padding: 0 !important;
        color: $gray-700 !important;
        // margin-top: 20px;
    }

    .btn-primary {
        background-color: $verde-principal;
        border: none;
    }

}
