.oportunidades {

    .scrolable {
        display: flex;
        flex-wrap: wrap;
        margin-top: 200px;
    }

    .area-terreno {
        margin-left: 30px;
        height: 25px;
        margin-right: 10px;
        margin-top: 23px;
        margin-bottom: 10px;
        font-size: 13px;
        font-weight: bold;
        color: #767676;
        background: #F6F7F8;
        border: 1px solid #767676;
        border-radius: 24px;
        padding-left: 7px;
        padding-right: 7px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .filter-container {

        background-color: white;
        width: 100%;
        width: 100vw;
        position: fixed;
        margin-top: -24px;
        left: 0;
        z-index: 100;
        height: 166px;

        .sliders {
            font-size: 20px;
            color: $roxo;
        }

        .search-icon {
            line-height: 2.5;
            margin-right: 11px;
        }

        .filter-line {

            display: inline-flex;
            margin-bottom: 25px;
            padding-top: 25px;
            justify-content: center;
            width: 100%;

            .async-select {
                width: 400px;
                margin-right: 10px;

                input {
                    background-color: rgba(0,0,0,0) !important;
                    color: black !important;
                }
            }

            .capsule-filter {
                cursor: pointer;
                border: 1px solid #4FE48B;
                border-radius: 15px;
                padding-left: 10px;
                padding-right: 10px;
                margin-right: 10px;
                padding-top: 7px;

                .excluir-icon {
                    margin-left: 5px;
                }

                span {
                    color: #4FE48B;
                }

                i {
                    color: #4FE48B;
                    margin-right: 5px;
                }
            }
        }
    }

    .item-container {
        flex: 25%;
        margin-left: 30px;
        margin-right: 30px;
        max-width: 350px;
        box-shadow: 0 4px 2px -2px gray;
        border-radius: 8px;
        background-color: white;
        margin-bottom: 30px;
        padding: 12px;

        .codigo-ref {
            position: absolute;
            z-index: 10;
            color: white;
            margin-left: 12px;
            margin-top: 3px;
        }

        .tags-container {

            display: flex;
            margin-bottom: 10px;

            .tags {

                i {
                    margin-right: 5px;
                }

                margin-right: 5px;
                padding-left: 7px;
                padding-right: 7px;
                font-size: 12px;
                color: #767676;
                background: #F6F7F8;
                border: 1px solid #767676;
                border-radius: 24px;
            }
        }
        

        &:hover {
            box-shadow: -3px 1px 4px rgba(0, 0, 0, 0.15);
            cursor: pointer;

            .thumb-container {
                background: black;
                overflow: hidden;
                border-radius: 8px;
            }

            .thumb {
                
                opacity: 0.6;
            }
        }

        .location-container {

            i {
                color: $roxo;
            }

            span {
                margin-left: 10px;
                font-weight: bold;
            }
        }

        .thumb-container {
            position: relative;

            .acervo-icon {
                font-size: 16px;
                color: #4FE48B;
                right: 10px;
                top: 10px;
                position: absolute;
            }

            .thumb {
                height: 130px;
                width: 100%;
                object-fit: cover;
                border-radius: 10px;
            }

            .label-vgv {
                background-color: white;
                border-radius: 4px;
                height: 22px;
                padding-right: 5px;
                padding-left: 5px;
            }

            .titulo-vgv {
                height: 22px;
                border-radius: 4px;
                padding-left: 5px;
                padding-right: 5px;
                padding-top: 2px;
                padding-bottom: 1px;
                margin-left: -2px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                font-size: 14px;
                font-weight: 600;
                background: rgba(255, 255, 255, 0.64);
            }

            .vgv-container {

                div {
                    margin-right: 3px;
                }

                right: 10px;
                display: flex;
                position: absolute;
                bottom: 5px;
                border-radius: 4px;
            }
        }

        .titulo {
            font-size: 16px;
            line-height: 4;
            margin-left: 10px;
            font-weight: bold;
        }

        .tipologia-container {
            display: flex;
        }
    
        .tipologia-thumb {
            height: 60px;
            width: 60px;
        }
    
    }

}

.modal-filtro {
    width: 600px;
}