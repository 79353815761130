.side-detail {

	position: absolute;
	top: 0px;
	left: -400px;
	height: 100%;
	width: 400px;
	z-index: 4;
	transition: left .3s ease-in-out;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
	background-color: rgba(0,0,0,0.65);

    &::-webkit-scrollbar {
        width: 10px;
        background-color: $azul-principal;
        overflow-x: hidden;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $azul-aleatorio;
        border-radius: 20px;
        border: 1px solid white;
        overflow-x: hidden;
    }

    audio {
        width: 100%;
    }

    .card {
        background-color: transparent !important;
    }

    .card-header {
        background-color: rgba(44,63,144, 0.7);
    }

    .file-container {

        .file-item {
            padding: 5px;
            cursor: pointer;
            padding-bottom: 20px;
            border: 1px solid white;
			margin-top: 10px;
			width: 90%;

            div {
                display: inline-flex;
                width: 100%;
            }
		}
		
		.file-item-image {
			padding: 5px;
            cursor: pointer;
            padding-bottom: 20px;
            border: 1px solid white;
			margin-top: 10px;
			width: 90%;

			img {
				width: 100%;
			}
		}

        label {
            cursor: pointer;
        }

        i {
            font-size: 28px;
            color: white;
            padding: 9px;
            line-height: 1.2;
        }

        .label-date {
            font-size: 10px;
            float: right;
        }
    }

    .spinner-results {
        top: 30%;
        left: 25%;
    }

	.icon-open {
		position: absolute;
		top: 45%;
		font-size: 24px;
		color: white;
		left: 372px;
		z-index: 5;
	}

	&.open {
		left: 0;
	}

	.sidemenu-container {
		margin-top: 20px;
		padding: 15px;
	}

	.sidemenu-item {
		padding: 20px;
		color: white;
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 10px;

		&:hover {
			background-color: rgba(0,0,0,0.8);
			cursor: pointer;
		}
	}

    .active {
        background-color: $gray-700;
	}
	
	label {
		color: white;
	}
}

.modal-tag {

    width: 70vw;
    max-width: 70vw;

    .modal-body {
        text-align: center;
    }

    video {
        width: fit-content;
    }

    .select-folder {
        width: 50%;
        margin: auto;  
    }
}