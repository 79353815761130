.onboard {

	height: 100vh;
	background-color: $roxo;
    text-align: center;

    .logo-step {
        width: 60%;
        margin: auto;
        margin-top: 15%;
    }
    
    .logo {
        width: 60%;
        margin: auto;
        margin-top: 25%;
    }

    .text {
        color: white;
        padding: 20px;
        font-weight: 600;
    }

    .step-footer {
        font-size: 20px;
        border-radius: 20px;
        padding: 15px;
        width: 90%;
        text-align: left;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .white-portion {
        color: $roxo;
        font-size: 20px;
        background-color: white;
        display: inline-flex;
        border-radius: 20px;
        border: 1px solid $verde-principal;
        padding: 15px;
        width: 90%;
        text-align: left;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .icone {
        color: $verde-principal;
        margin-right: 10px;
        margin-left: 10px; 
    }

    .button-blank {
        font-size: 22px;
        padding: 15px;
        background-color: transparent;
        color: white;
        border: none;
    }

    .button-principal {
        font-size: 22px;
        padding: 15px;
        float: right;

        .b-icon {
            margin-left: 5px;
        }
    }

    .width-100 {
        width: 100%;
    }

    .dots {
        display: inline-flex;
    }

    .dash {
        color: #4FE48B;
        border: 8px solid #4FE48B;
        width: 33px;
        margin-left: 4px;
        border-radius: 15px;
    }

    .dot {
        color: #BCA9F5;
        border: 8px solid #BCA9F5;
        margin-left: 4px;
        width: 3px;
        border-radius: 15px;
    }

}
