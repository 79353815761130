.sidemenu-360 {

	position: absolute;
	top: 0px;
	left: -260px;
	height: 100%;
	width: 300px;
	z-index: 4;
	transition: left .3s ease-in-out;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
	background-color: rgba(0,0,0,0.65);

    &::-webkit-scrollbar {
        width: 10px;
        background-color: $azul-principal;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $azul-aleatorio;
        border-radius: 20px;
        border: 1px solid white;
    }

	.icon-open {
		position: absolute;
		top: 45%;
		font-size: 24px;
		color: white;
		left: 265px;
		z-index: 5;
	}

	&.open {
		left: 0;
	}

	.sidemenu-container {
		margin-top: 20px;
	}

	.sidemenu-item {
		padding: 20px;
		color: white;
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 10px;

		&:hover {
			background-color: rgba(0,0,0,0.8);
			cursor: pointer;
		}
	}

    .active {
        background-color: $gray-700;
    }
}
