.modal-oportunidade {

	margin: 0;

    button {
        background-color: $roxo;
        border: none;
        padding: 10px;
        font-weight: bold;
        margin-left: 25%;
        margin-top: 20px;

        i {
            margin-right: 10px;
        }
    }

    .full-container {

        overflow-y: auto;
        max-height: 50vh;

        &::-webkit-scrollbar {
            width: 10px;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: $roxo;
            border-radius: 20px;
            border: 1px solid white;
        }

        .content {
            padding-left: 30px;
            padding-right: 30px;
            // max-height: 51vh;
            // overflow-y: auto;
    
            // &::-webkit-scrollbar {
            //     width: 10px;
            // }
        
            // &::-webkit-scrollbar-thumb {
            //     background-color: $roxo;
            //     border-radius: 20px;
            //     border: 1px solid white;
            // }
        }
    }

    .info-container {

        .divider {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .info-value {
            float: right;
            font-weight: bold;
        }
    }

    .header-image {
        margin-top: 58px;
        width: 100%;
        height: 300px;
        max-height: 40vh;
    }

    h1 {
        color: $roxo;
        font-size: 23px;
        font-weight: bold;
        display: flex;
        margin-bottom: 15px;

        &:after {
            content: '';
            flex-grow: 1;
            height: 1px;
            background: $roxo;
            min-width: 20px;
            margin: auto;
            margin-left: 10px;
            height: 2px;
          }
    }

    .header-detail {

        box-shadow: 0 4px 2px -2px gray;
        width: 100%;
        position: absolute;
        padding: 10px;
        padding-top: 0;
        top: 0px;
        left: 0px;
        background-color: white;
        border-radius: 12px;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        display: flex;

        .tipologia-container {
            height: 55px;
            display: contents;
        }

        .titulo {
            font-size: 16px;
            line-height: 4;
            margin-left: 10px;
            margin-right: 10px;
            font-weight: bold;
        }

        .acervo-icon {
            font-size: 16px;
            color: #4FE48B;
            right: 10px;
            top: 25px;
            position: absolute;
        }

        .tipologia-thumb {
            height: 60px;
            width: 60px;
        }

        .location-container {
            margin-top: 20px;
            margin-left: auto;
            margin-right: 35px;

            i {
                color: $roxo;
            }

            span {
                margin-left: 10px;
                font-weight: bold;
            }
        }
    }

	.modal-body {
		overflow-y: hidden;
		padding: 0;
	}

	.modal-content {
		margin: 0 ;
		position: absolute;
		right: -1600px;
		height: 100vh;
		width: 30%;
		transition: 0.5s;

		-webkit-box-shadow: -10px 0 30px rgba(0,0,0,0.3);
		-moz-box-shadow: -10px 0 30px rgba(0,0,0,0.3);
		box-shadow: -10px 0 30px rgba(0,0,0,0.3);
	}

	.modal-header {
		height: 100px;
		padding-bottom: 23px;
		background-image: linear-gradient(to right, $azul-secundario, $azul-escuro);

		i {
			position: absolute;
			left: -15px;
			top: 27px;
			font-size: 18px;
			color: #2daae1;
			background-color: white;
			padding-left: 15px;
			padding-right: 16px;
			padding-top: 10px;
			padding-bottom: 10px;
			border-radius: 2em;
			cursor: pointer;
		}

		h5 {
			color: white;
			font-size: 40px;
			padding-left: 25px;
		}
	}

	.card-header {

		padding-right: 24px;
		padding-left: 24px;
		padding-top: 24px;

		.buttons {
			float: left;

			button {
				margin-right: 15px;
				margin-left: 0;
			}

		}

		border-top: 3px solid $azul-escuro;
		border-bottom: none;
		background-color: white;
		color: $cinza-escuro;

	}

}

.slide {

	.modal-content {
		right: 0;
		transition: 0.5s;
	}
}
