.slider-container {

    position: inherit;
    padding-bottom: 15px;
    padding-top: 15px;

    .selector {
        background-color: #4FE48B;
        padding: 8px;
        border-radius: 15px;
        width: 26px;
    }
}