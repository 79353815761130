.modal-oportunidade-completa {

    margin: 0;

    .map-container {
        height: 80vh;
    }

    h4 {
        font-size: 1.2rem;
    }

    .blur {
        color: transparent;
        text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    }

    .capsule {
        border: 1px solid black;
        border-radius: 15px;
        padding: 5px;
        padding-left: 14px;
        padding-right: 14px;
    }

    .pencil-green {
        color: #4FE48B;
        float: right;
        margin-top: 4px;
        margin-right: 10px;
    }

    .valores-label-component {

        // margin-top: 10px;

        .roi-label {
            color: #4FE48B;
            margin-left: 25px;
            font-size: 24px;
            font-weight: bold;
        }

        .valores-value {
            font-size: 18px;
            font-weight: bold;
        }
    }

    .calc-input {
        background-color: white !important;
        color: #4FE48B !important;
        float: right;
        width: 75px;
        text-align: right;
    }

    .green-label {

        i,
        span {
            color: #4FE48B;
            margin-right: 10px;
        }
    }

    .info-value-calc {
        float: right;
    }

    .mark {
        background-color: #E0E0E0;
    }

    .info-value-calc-green {
        float: right;
        color: #4FE48B;
        font-weight: bold;
    }

    .green-container {
        margin-bottom: 20px;
        background-color: #4EE38A2E;
        padding: 20px;

        .info-value-green {
            float: right;
        }

        span {
            font-weight: 600;
        }
    }

    button {
        background-color: $roxo;
        border: none;
        padding: 10px;
        font-weight: bold;
        margin-left: 40%;
        margin-top: 5px;

        i {
            margin-right: 10px;
        }
    }

    .content {
        padding-left: 30px;
        padding-right: 30px;
        max-height: 90vh;
        margin-top: 40px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $roxo;
            border-radius: 20px;
            border: 1px solid white;
        }
    }

    .section {
        margin-top: 20px;
    }

    .obs-container {
        text-align: justify;

        .obsercavao-field {
            height: 100px;
            display: block;
        }
    }

    .section-container {
        display: inline-flex;
        width: 100%;
        overflow-x: auto;
        padding-top: 5px;

        &::-webkit-scrollbar-track {
            background: white;
            border: none;
        }

        &::-webkit-scrollbar-button:single-button {
            background-color: white;
            display: block;
            border-style: solid;
            height: 13px;
            width: 16px;
        }

        &::-webkit-scrollbar-button:horizontal:decrement {
            border-width: 7px 14px 7px 0;
            border-color: transparent #404040 transparent transparent;
        }

        &::-webkit-scrollbar-button:horizontal:decrement:hover {
            border-color: transparent #505050 transparent transparent;
        }

        &::-webkit-scrollbar-button:horizontal:increment {
            border-width: 7px 0 7px 14px;
            border-color: transparent transparent transparent #404040;
        }

        &::-webkit-scrollbar-button:horizontal:increment:hover {
            border-color: transparent transparent transparent #505050;
        }

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $roxo;
            border-radius: 20px;
            border: 1px solid white;
        }

        .column-container {
            text-align: center;

            &:first-child {
                text-align: left;
                white-space: nowrap;
            }

            .info-value-vert {
                text-align: right;
            }

            .info-container {

                .divider {
                    margin-top: 15px;
                    margin-bottom: 15px;
                }

                .info-value {
                    font-weight: bold;
                    margin-right: 10px;
                    white-space: nowrap;
                }
            }
        }
    }

    .big-divider {
        margin-top: 60px;
        margin-bottom: 60px;
        border: 1px solid $gray-300;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .image-side {
        width: 100%;
        height: 450px;
        object-fit: fill;
    }

    .image {
        width: 100%;
    }

    .title-container {
        display: flex;
        // margin-top: 30px;
        // margin-bottom: 30px;

        i {
            color: $roxo;
            font-size: 23px;
            margin-right: 5px;
        }
    }

    h1 {
        color: $roxo;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        margin-bottom: 10px;
        width: 100%;

        &:after {
            content: '';
            flex-grow: 1;
            height: 1px;
            background: $roxo;
            min-width: 50%;
            margin: auto;
            margin-left: 10px;
            height: 2px;
        }
    }

    .scroll-container {

        .h-menu-container {
            display: inline-flex;

            .h-menu-item {
                cursor: pointer;
                color: $roxo;
                font-size: 22px;
                margin-right: 30px;
                font-weight: bold;
                white-space: nowrap;

                i {
                    margin-right: 5px;
                }
            }
        }
    }

    .header-detail {

        width: 100%;
        position: absolute;
        padding: 10px;
        padding-top: 0;
        top: 0px;
        left: 0px;
        background-color: white;
        border-radius: 12px;

        .top-header {
            padding-bottom: 10px;
            display: flex;
            width: 100%;
            box-shadow: 0 4px 2px -2px gray;
            border-radius: 12px;
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
        }

        .subheader {
            margin-top: 20px;
        }

        .tipologia-container {
            height: 55px;
        }

        .titulo {
            font-size: 16px;
            line-height: 4;
            margin-left: 10px;
            font-weight: bold;
        }

        .acervo-icon {
            font-size: 16px;
            color: #4FE48B;
            right: 10px;
            top: 25px;
            position: absolute;
        }

        .tipologia-thumb {
            height: 60px;
            width: 60px;
        }

        .location-container {
            margin-top: 20px;
            margin-left: auto;
            margin-right: 35px;

            i {
                color: $roxo;
            }

            span {
                margin-left: 10px;
                font-weight: bold;
            }
        }
    }

    .modal-body {
        overflow-y: hidden;
        padding: 0;
    }

    .modal-content {
        margin: 0;
        position: absolute;
        right: -1600px;
        height: 100vh;
        width: 70%;
        transition: 0.5s;

        -webkit-box-shadow: -10px 0 30px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: -10px 0 30px rgba(0, 0, 0, 0.3);
        box-shadow: -10px 0 30px rgba(0, 0, 0, 0.3);
    }

    .modal-header {
        height: 100px;
        padding-bottom: 23px;
        background-image: linear-gradient(to right, $azul-secundario, $azul-escuro);

        i {
            position: absolute;
            left: -15px;
            top: 27px;
            font-size: 18px;
            color: #2daae1;
            background-color: white;
            padding-left: 15px;
            padding-right: 16px;
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 2em;
            cursor: pointer;
        }

        h5 {
            color: white;
            font-size: 40px;
            padding-left: 25px;
        }
    }

    .card-header {

        padding-right: 24px;
        padding-left: 24px;
        padding-top: 24px;

        .buttons {
            float: left;

            button {
                margin-right: 15px;
                margin-left: 0;
            }

        }

        border-top: 3px solid $azul-escuro;
        border-bottom: none;
        background-color: white;
        color: $cinza-escuro;

    }

}

.slide {

    .modal-content {
        right: 0;
        transition: 0.5s;
    }
}