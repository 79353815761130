.login {

	height: 100vh;
	background-color: $roxo;

    .container {
        width: 36%;
        margin-right: 62%;
        padding-top: 15%;
    }

    .city {
        background-color: #ecf2f4;
        height: 100%;
        overflow: hidden;
        width: 80%;
        position: fixed;
        left: 40%;
    }

    .logo {
        width: 60%;
        margin: auto;
        margin-top: -15%;
        margin-bottom: 20px;
    }

    .forgot-pass {
        text-align: left;
        text-decoration: none;
        padding: 0;
        margin-top: 5px;
    }

    .btn-primary {
        font-size: 20px;
        border-radius: 10px;
        padding: 10px;
        margin-top: 35px !important;
    }

    .row {
        padding: 15px;
    }

    label {
        margin-top: 20px;
    }

    .btn-link {
        color: white;
    }

    .btn-primary {
        margin-top: 10px;
    }

	.link-login {
		color: white;
		font-weight: bolder;
		text-decoration: underline;
	}

	.alert {
		top: 0;
		position: absolute;
		width: 100%;
		text-align: center;
		font-size: 18px;
		font-weight: 800;
	}

}
