.curadoria {

    .scrolable {
        display: flex;
        flex-wrap: wrap;
        margin-top: 200px;
    }

    .area-terreno {
        margin-left: 30px;
        height: 25px;
        margin-right: 10px;
        margin-top: 23px;
        margin-bottom: 10px;
        font-size: 13px;
        font-weight: bold;
        color: #767676;
        background: #F6F7F8;
        border: 1px solid #767676;
        border-radius: 24px;
        padding-left: 7px;
        padding-right: 7px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .situacao-capsule {
        margin-top: 5px;
        text-align: center;
        border-radius: 24px;

        i {
            margin-right: 10px;
        }
    }

    .green {
        background-color: #85FFB5;
        color: #319F55;
    }

    .red {
        background-color: #FFDFD6;
        color: #FF2E00;
    }

    .yellow {
        background-color: #FFF3D6;
        color: #FFA800;
    }

    .item-container {
        flex: 25%;
        margin-left: 30px;
        margin-right: 30px;
        max-width: 350px;
        box-shadow: 0 4px 2px -2px gray;
        border-radius: 8px;
        background-color: white;
        margin-bottom: 30px;
        padding: 12px;

        .codigo-ref {
            position: absolute;
            z-index: 10;
            color: white;
            margin-left: 12px;
            margin-top: 3px;
        }

        .tags-container {

            display: flex;
            margin-bottom: 10px;

            .tags {

                i {
                    margin-right: 5px;
                }

                margin-right: 5px;
                padding-left: 7px;
                padding-right: 7px;
                font-size: 12px;
                color: #767676;
                background: #F6F7F8;
                border: 1px solid #767676;
                border-radius: 24px;
            }
        }
        

        &:hover {
            box-shadow: -3px 1px 4px rgba(0, 0, 0, 0.15);
            cursor: pointer;

            .thumb-container {
                background: black;
                overflow: hidden;
                border-radius: 8px;
            }

            .thumb {
                
                opacity: 0.6;
            }
        }

        .location-container {

            i {
                color: $roxo;
            }

            span {
                margin-left: 10px;
                font-weight: bold;
            }
        }

        .thumb-container {
            position: relative;

            .acervo-icon {
                font-size: 16px;
                color: #4FE48B;
                right: 10px;
                top: 10px;
                position: absolute;
            }

            .thumb {
                height: 130px;
                width: 100%;
                object-fit: cover;
                border-radius: 10px;
            }

            .label-vgv {
                background-color: white;
                border-radius: 4px;
                height: 22px;
                padding-right: 5px;
                padding-left: 5px;
            }

            .titulo-vgv {
                height: 22px;
                border-radius: 4px;
                padding-left: 5px;
                padding-right: 5px;
                padding-top: 2px;
                padding-bottom: 1px;
                margin-left: -2px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                font-size: 14px;
                font-weight: 600;
                background: rgba(255, 255, 255, 0.64);
            }

            .vgv-container {

                div {
                    margin-right: 3px;
                }

                right: 10px;
                display: flex;
                position: absolute;
                bottom: 5px;
                border-radius: 4px;
            }
        }

        .titulo {
            font-size: 16px;
            line-height: 4;
            margin-left: 10px;
            font-weight: bold;
        }

        .tipologia-container {
            display: flex;
        }
    
        .tipologia-thumb {
            height: 60px;
            width: 60px;
        }
    
    }

}
